import './App.css';
import Header from './components/header'
import Profile from './components/profile'

function App() {
  return (
    <div className="App">
      <div className="Content">
        <Header />
      </div>
      <div className="Empty">
        <Profile />
      </div>
    </div>

  );
}

export default App;
