import React, { useState } from 'react'
import emailjs from 'emailjs-com';


const Form = () => {

    const [ state, setState ] = useState({
        user_name : '',
        phone : '',
        email : '',
        message : ''
    });

    const [ nameError, setNameError ] = useState({});
    const [ phoneError, setPhoneError ] = useState({});
    const [ emailError, setEmailError ] = useState({});
    const [ messageError, setMessageError ] = useState({});


    const handleChange = event => {
        setState({ ...state, [ event.target.name ] : event.target.value });
    }

    const handleSubmit = e => {
        e.preventDefault();
        const isValid = formValidation();
        if ( isValid ) {
            emailjs.sendForm('service_yl1kb4y', 'template_88ebbas', e.target, 'user_jjVSjQHB5kchFYrJYDcRG')
            .then((result) => {
                console.log(result.text);
                window.location.reload();
            }, (error) => {
                console.log(error.text);
            });
        }

    }

    const formValidation = () => {
        const nameErr = {};
        const phoneErr = {};
        const emailErr = {};
        const messageErr = {};

        let isValid = true;

        if (state.user_name.length < 3) {
            nameErr.nameShort = "Please enter your full name";
            isValid = false;
        }

        if (state.phone.length !== 10) {
            phoneErr.phoneShort = "Please enter a phone number with 10 digits";
            isValid = false;
        }

        if (!state.email.includes("@") || !state.email.includes(".")) {
            emailErr.emailFail = "Please enter a valid email";
            isValid = false;
        }

        if (state.message.length < 5) {
            messageErr.messageShort = "Please enter a longer description"
            isValid = false;
        }

        setNameError(nameErr);
        setPhoneError(phoneErr);
        setEmailError(emailErr);
        setMessageError(messageErr);

        return isValid;

    }

    return (
        <div>
            <form action="/" className="contact-form" onSubmit ={ handleSubmit }>
                <input type="text" name="user_name" placeholder="first name" value={ state.user_name } onChange={ handleChange } /><br />
                { Object.keys(nameError).map((key) => {
                    return <span className="error">{ nameError[key] }</span>
                })}
                <input type="text" name="phone" placeholder="phone number" value={ state.phone } onChange={ handleChange } /><br />
                { Object.keys(phoneError).map((key) => {
                    return <span className="error">{ phoneError[key] }</span>
                })}
                <input type="email" name="email" placeholder="email" value={ state.email } onChange={ handleChange } /><br />
                { Object.keys(emailError).map((key) => {
                    return <span className="error">{ emailError[key] }</span>
                })}
                <textarea type="text" name="message" placeholder="let me know how i can assist you, i am always happy to help!" value={ state.message } onChange={ handleChange } /><br />
                { Object.keys(messageError).map((key) => {
                    return <span className="error">{ messageError[key] }</span>
                })}
                <button type="submit" id="special">SEND</button>
            </form>
        </div>
    );
};



const About = () => {
    return (
        <div id="about">
            <div id="about-container">
                <div id="about-content">
                    <h3>Gabriel Veloz</h3>
                    <h5>software engineer / drummer</h5>
                    <br />
                    <div className="typewriter">
                        <h1>i enjoy being creative with technology.</h1>
                    </div>
                    <br />
                    <a href="https://github.com/V3L0Z"><i className="fab fa-github fa-2x"></i></a>
                    <a href="https://www.linkedin.com/in/v3l0z/"><i className="fab fa-linkedin-in fa-2x"></i></a>
                    <a href="https://www.youtube.com/channel/UCPosBOtphZoGdu17BYbW2sQ"><i className="fab fa-youtube fa-2x"></i></a>
                    <br /><br />
                    <Form />
                </div>
            </div>
        </div>
    );
};

export default About;