import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import About from './about'
import Resume from './resume'
import Projects from './projects'

const Header = () => { 
    return (
        <Router>
            <div id="header">
                <div id="header-container">
                    <ul>
                        <li id="logo"> 
                            <Link to="/about"><i className="fas fa-terminal fa-2x"></i></Link>                    
                        </li>
                        <li className="menu-item">
                            <Link to="/resume">resume</Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/projects">projects</Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/about">about</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Switch>
                <Route exact path="/">
                    <About />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/resume">
                    <Resume />
                </Route>
                <Route path="/projects">
                    <Projects />
                </Route>
            </Switch>
        </Router>
    ); 
};

export default Header;
