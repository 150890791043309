import React from 'react'


const Resume = () => {
    return (
        <div id="resume">
            <div id="resume-container">
                <div id="resume-content"> 
                    <h3>resume</h3>
                    <br />
                    <h1>
                        Hello, my name is Gabriel and I am a Front End Software Engineer.
                        I graduated with a Bachelors degree in Software Engineering from California Baptist University.
                    </h1>
                    <br />
                    <br />
                    <h1>
                        I have worked for numerous firms including Tesla and Fluke as an engineer.
                    </h1>
                    <br />
                    <br />
                    <h1>
                        I am skilled in: Python, Javascript, React.js, Bootstrap, HTML, CSS, Wordpress, AWS, Firebase, and SQL.
                    </h1>
                    <br />
                    <br />
                    <h1>
                        I enjoy helping people grow their business. Feel free to contact me on the about page to send me a message!
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default Resume;