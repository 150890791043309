import React from 'react'
import profile from '../images/profile.jpg'


const Profile = () => {
    return (
        <div id="profile">
            <div id="profile-container">
                <img src={ profile } alt="" />
            </div>
        </div>
    );
};

export default Profile;