import React from 'react'


const Projects = () => {
    return (
        <div id="projects">
            <div id="projects-container">
                <div id="projects-content">
                    <ul>
                        <h3>recent projects</h3>
                        <li><a href="https://sirenaspartyland.com"><p>Serenas Party Land</p></a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Projects;